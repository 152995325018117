import Block from '../../adapters/helpers/Block';
import React, {Suspense} from 'react'
import Loader from '../../adapters/helpers/Loader';

function HTMLBlock(props) {
    const block = new Block(props);
    const id = block.getAnchorId();
    const className = 'ob-html-block ' + block.getFieldValue('classNames');
    const text = block.getFieldValue('text');

    return (
        <Suspense fallback={<Loader/>}>
            <div
                id={id}
                className={className}
                dangerouslySetInnerHTML={{__html: text}}
            >
            </div>
        </Suspense>
    )
}

HTMLBlock.propTypes = {

};

export default HTMLBlock;

